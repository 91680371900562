<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">

        <b-row v-if="isRealodByNotification">
            <b-col cols="12" xl="7" md="7">
                <div class="demo-spacing-2">
                    <b-alert variant="danger" show>
                        <div class="alert-body">
                            <feather-icon class="mr-25" icon="AlertTriangleIcon"/>
                            <span><strong>Alerta</strong> volver a realizar la busqueda, hay nuevas facturas abiertas listos para enrutar...</span>
                        </div>
                    </b-alert>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" xl="7" md="7">
                
                <!-- Tabs -->
                <b-card no-body>
                    <b-tabs fill pill v-model="activeTab">
                        <b-tab @click="changeTab(0)" title="Buscador">
                            <div class="m-1">
                                <b-row>
                                    <b-col cols="12" md="2">
                                        <b-form-group label="Fecha Inicio" label-for="date_start">
                                            <flat-pickr v-model="filter.date_start" id="date_start" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                                placeholder="DD/MM/YYYY" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="2">
                                        <b-form-group label="Fecha Fin" label-for="date_start">
                                            <flat-pickr v-model="filter.date_end" id="date_end" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                                placeholder="DD/MM/YYYY" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="4">
                                        <b-form-group label="Estatus" label-for="status">
                                            <b-form-select size="sm" v-model="filter.status" id="status" :options="['TODAS', 'ABIERTA', 'EN RUTA', 'CLIENTE PASA']" class="select-size-sm"
                                                placeholder="Selecciona una opción" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="4">
                                        <b-form-group label="Almacen" label-for="warehouse" label-class="font-weight-bold">
                                            <v-select                                                
                                                id="warehouse"
                                                v-model="wareHouse_Id"
                                                :options="wareHouses"                        
                                                label="nombre"
                                                :reduce="option => option.almacen_Id"
                                                :clearable="false"
                                                :searchable="false"
                                                class="select-size-sm"
                                                placeholder="Selecciona una opción">
                                            </v-select>
                                        </b-form-group>
                                    </b-col>

                                                                       

                                    <b-col cols="12" md="8" class="mt-0">
                                        <b-input-group>
                                            <b-form-input v-model="searchText" size="sm" placeholder="Filtrar por folio, cliente, origen, portador, asignado, almacen" />
                                        </b-input-group>
                                    </b-col>

                                    <b-col cols="12" md="4" class="mt-0">
                                        <b-button :disabled="loading" @click="getDeliveryRoutings" size="sm" variant="primary" class="mr-1">
                                            <feather-icon icon="SearchIcon" /> Buscar
                                        </b-button>

                                        <b-button size="sm" variant="info"
                                            v-b-toggle.sidebar-right>
                                            <feather-icon icon="SlidersIcon" /> Ordenar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>

                            <b-row>
                                <b-col cols="12">
                                    <div class="table-responsive">
                                        <div class="position-relative b-table-responsive mb-0 text-small small b-table-sticky-header table-responsive">
                                            <table class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">% Entrega</th>
                                                        <th class="text-center">Folio</th>
                                                        <th class="text-center">Fecha Factura</th>
                                                        <th v-if="showColumnOrigin" class="text-center">Origen</th>
                                                        <th class="text-center">Cliente</th>
                                                        <th class="text-center">Almacen</th>
                                                        <th class="text-center">Portador</th>
                                                        <th class="text-center">Asignado</th>
                                                        <th class="text-center">Saldo</th>
                                                        <th class="text-center">Estatus</th>
                                                        <th class="text-center">Impreso</th>
                                                        <th class="text-center">Instrucciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in filterRoutings" :key="index" @click="selectRow(item, index)"
                                                        :class="{ 
                                                            'selected-row': isSelected(index),
                                                        }">
                                                        <td>
                                                            <b-badge pill :variant="setColorClass(item)"><small>{{ setDeliveryPercent(item) }}%</small></b-badge>
                                                        </td>
                                                        <td class="text-capitalize">{{ item.folio }}</td>
                                                        <td class="text-capitalize">{{ formatDate(item.fecha_Factura) }}</td>
                                                        <td v-if="showColumnOrigin" class="text-capitalize">{{ item.nombre_Sucursal }}</td>
                                                        <td class="text-capitalize">{{ item.nombre_Cliente }}</td>
                                                        <td class="text-capitalize">{{ item.nombre_Almacen }}</td>
                                                        <td class="text-capitalize">{{ item.portador }}</td>
                                                        <td class="text-capitalize">{{ item.asignado }}</td>
                                                        <td class="text-capitalize">{{ formatCurrency(item.saldo) }}</td>
                                                        <td class="text-capitalize">{{ item.estatus }}</td>
                                                        <td class="text-capitalize">
                                                            <b-badge :variant="item.impreso ? 'success' : 'danger'" pill>
                                                                <small>{{ item.impreso ? 'Si' : 'No' }}</small>
                                                            </b-badge>
                                                        </td>
                                                        <td class="text-center">
                                                            <b-badge :variant="diasPlazoVariant(item)" pill>
                                                                <small>{{ diasPlazoLabel(item) }}</small>
                                                            </b-badge>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <!-- Pagination -->
                                    <div v-if="filterRoutings.length > 0" class="mx-2 mb-2">
                                        <b-row>
                                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                                <span class="text-muted">{{ resolvePaginationTranslate(dataMetaRouting) }}</span>
                                            </b-col>
                                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                                <div class="mt-2 demo-spacing-0">
                                                    <b-pagination v-model="currentPageRouting" :total-rows="totalRecordsRouting" :per-page="pageSizeRouting"/>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-col>
                            </b-row>
                           
                        </b-tab>
                        <b-tab @click="changeTab(1)" title="Rutas Asignadas">

                            <div class="m-1">
                                <b-row>
                                    <b-col cols="6">
                                        <b-form-group label="Chofer" label-for="chofer_filter" label-class="font-weight-bold">
                                            <v-select
                                                id="chofer_filter"
                                                v-model="filter.chofer_Id"
                                                :options="operators" 
                                                :reduce="option => option.id"
                                                label="label"
                                                :clearable="true"
                                                :searchable="true"
                                                class="select-size-sm"
                                                placeholder="Selecciona una opción" 
                                                @input="selectChofer"/>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="6">
                                        <b-form-group label="Camion" label-for="truck_filter" label-class="font-weight-bold">
                                            <v-select
                                                id="truck_filter"
                                                v-model="filter.truck_Id"
                                                :options="trucks"
                                                :reduce="option => option.id"
                                                label="label"
                                                :clearable="true"
                                                :searchable="true"
                                                class="select-size-sm"
                                                placeholder="Selecciona una opción"
                                                @input="selectTruck" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="10" md="6">                                        
                                        <b-form-input v-model="searchTextAssigned" size="sm" placeholder="Filtrar por folio, cliente, chofer, unidad" />
                                    </b-col>

                                    <b-col cols="12" md="4">
                                        <b-button :disabled="loading" @click="getAssignedRoutings(1)" size="sm" variant="primary" class="mr-1">
                                            <feather-icon icon="SearchIcon" /> Buscar
                                        </b-button>

                                        <b-button size="sm" variant="info"
                                            v-b-toggle.sidebar-right>
                                            <feather-icon icon="SlidersIcon" /> Ordenar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>

                            <div class="table-responsive">
                                <div class="position-relative b-table-responsive mb-0 text-small small b-table-sticky-header table-responsive">
                                    <table class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                                        <thead>
                                            <tr>
                                                <th class="text-center">% Entrega</th>
                                                <th class="text-center">Instrucciones</th>
                                                <th class="text-center">Impreso</th>
                                                <th class="text-center">Unidad</th>
                                                <th class="text-center">Chofer</th>
                                                <th class="text-center">Fecha Factura</th>
                                                <th class="text-center">Fecha Asignación</th>
                                                <th class="text-center">Factura</th>
                                                <th class="text-center">Cliente</th>
                                                <th class="text-center">Dirección</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in filterAssigneds" :key="index" @click="selectRow(item, index)"
                                            :class="{ 'selected-row': isSelected(index) }">
                                                <td>
                                                    <b-badge pill :variant="setColorClass(item)"><small>{{ setDeliveryPercent(item) }}%</small></b-badge>
                                                </td>
                                                <td class="text-center">
                                                    <b-badge :variant="diasPlazoVariant(item)" pill>
                                                        <small>{{ diasPlazoLabel(item) }}</small>
                                                    </b-badge>
                                                </td>
                                                <td class="text-capitalize">
                                                    <b-badge :variant="item.impreso ? 'success' : 'danger'" pill>
                                                        <small>{{ item.impreso ? 'Si' : 'No' }}</small>
                                                    </b-badge>
                                                </td>
                                                <td class="text-capitalize" v-if="item.webCamiones">{{ item.webCamiones.identificador }} - {{ item.webCamiones.placas }}</td>
                                                <td class="text-capitalize" v-if="item.webOperadores">{{ item.webOperadores.nombre }} {{ item.webOperadores.apellidos }}</td>
                                                <td class="text-capitalize">{{ formatDate(item.fecha_Factura) }}</td>
                                                <td class="text-capitalize">{{ formatDate(item.fecha_Asignacion_Chofer) }}</td>
                                                <td class="text-capitalize">{{ item.folio }}</td>
                                                <td class="text-capitalize">{{ item.nombre_Cliente }}</td>
                                                <td class="text-wrap">{{ item.calle }} {{ item.num_Exterior }} {{ item.colonia }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>                               
                            </div>
                            
                            <!-- Pagination -->
                            <div v-if="filterAssigneds.length > 0" class="mx-2 mb-2">
                                <b-row>
                                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                        <span class="text-muted">{{ resolvePaginationTranslate(dataMeta) }}</span>
                                    </b-col>
                                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                        <div class="mt-2 demo-spacing-0">
                                            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"/>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-tab>

                        <b-tab @click="changeTab(2)" title="Rutas Cerradas">
                            <div class="m-1">
                                <b-row>
                                    <b-col cols="12" md="2">
                                        <b-form-group label="Fecha Inicio" label-for="date_start_closed">
                                            <flat-pickr v-model="filter.date_start_closed" id="date_start_closed" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                                placeholder="DD/MM/YYYY" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="2">
                                        <b-form-group label="Fecha Fin" label-for="date_end_closed">
                                            <flat-pickr v-model="filter.date_end_closed" id="date_end_closed" class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                                placeholder="DD/MM/YYYY" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="4">
                                        <b-form-group label="Estatus" label-for="status_closed">
                                            <b-form-input readonly size="sm" v-model="filter.status_closed" id="status_closed" placeholder="CERRADA" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="4">
                                        <b-form-group label="Almacen" label-for="warehouse" label-class="font-weight-bold">
                                            <v-select                                                
                                                id="warehouse"
                                                v-model="filter.warehouse_closed_Id"
                                                :options="wareHouses"                        
                                                label="nombre"
                                                :reduce="option => option.almacen_Id"
                                                :clearable="false"
                                                :searchable="false"
                                                class="select-size-sm"
                                                placeholder="Selecciona una opción">
                                            </v-select>
                                        </b-form-group>
                                    </b-col>                                                                      

                                    <b-col cols="12" md="8" class="mt-0">
                                        <b-input-group>
                                            <b-form-input v-model="filter.searchTextClosed" size="sm" placeholder="Filtrar por folio, cliente, origen, portador, asignado, almacen" />
                                        </b-input-group>
                                    </b-col>

                                    <b-col cols="12" md="4" class="mt-0">
                                        <b-button :disabled="loading" @click="getClosedRoutings(1)" size="sm" variant="primary" class="mr-1">
                                            <feather-icon icon="SearchIcon" /> Buscar
                                        </b-button>

                                        <b-button size="sm" variant="info"
                                            v-b-toggle.sidebar-right>
                                            <feather-icon icon="SlidersIcon" /> Ordenar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>

                           
                            <div class="table-responsive">
                                <div class="position-relative b-table-responsive mb-0 text-small small b-table-sticky-header table-responsive">
                                    <table class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                                        <thead>
                                            <tr>
                                                <th class="text-center">% Entrega</th>
                                                <th class="text-center">Folio</th>
                                                <th class="text-center">Fecha Factura</th>
                                                <th class="text-center">Origen</th>
                                                <th class="text-center">Cliente</th>
                                                <th class="text-center">Almacen</th>
                                                <th class="text-center">Portador</th>
                                                <th class="text-center">Asignado</th>
                                                <th class="text-center">Saldo</th>
                                                <th class="text-center">Impreso</th>
                                                <th class="text-center">Instrucciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in filterClosed" :key="index" @click="selectRow(item, index)"
                                                :class="{ 
                                                    'selected-row': isSelected(index),
                                                }">
                                                <td>
                                                    <b-badge pill :variant="setColorClass(item)"><small>{{ setDeliveryPercent(item) }}%</small></b-badge>
                                                </td>
                                                <td class="text-capitalize">{{ item.folio }}</td>
                                                <td class="text-capitalize">{{ formatDate(item.fecha_Factura) }}</td>
                                                <td class="text-capitalize">{{ item.nombre_Sucursal }}</td>
                                                <td class="text-capitalize">{{ item.nombre_Cliente }}</td>
                                                <td class="text-capitalize">{{ item.nombre_Almacen }}</td>
                                                <td class="text-capitalize">{{ item.portador }}</td>
                                                <td class="text-capitalize">{{ item.asignado }}</td>
                                                <td class="text-capitalize">{{ formatCurrency(item.saldo) }}</td>
                                                <td class="text-capitalize">
                                                    <b-badge :variant="item.impreso ? 'success' : 'danger'" pill>
                                                        <small>{{ item.impreso ? 'Si' : 'No' }}</small>
                                                    </b-badge>
                                                </td>
                                                <td class="text-center">
                                                    <b-badge :variant="diasPlazoVariant(item)" pill>
                                                        <small>{{ diasPlazoLabel(item) }}</small>
                                                    </b-badge>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- Pagination -->
                            <div v-if="filterClosed.length > 0" class="mx-2 mb-2">
                                <b-row>
                                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                        <span class="text-muted">{{ resolvePaginationTranslate(dataMetaClosedRouting) }}</span>
                                    </b-col>
                                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                        <div class="mt-2 demo-spacing-0">
                                            <b-pagination v-model="closeCurrentPageRouting" :total-rows="closeTotalRecordsRouting" :per-page="closePageSizeRouting"/>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                               
                        </b-tab>
                    </b-tabs>
                </b-card>              
            </b-col>

            <!-- Actions -->
            <b-col ref="invoiceActions" v-if="hasRouting && routing && routing.docto_Pv_Id" cols="12" md="5" xl="5" class="invoice-actions">
                <b-card no-body class="border-primary">
                    <div class="m-1">
                        <b-row>

                            <!-- Customer -->
                            <b-col cols="12">
                                <h6 class="mb-25">
                                    {{ routing.nombre_Cliente }} <span class="text-end float-right">FOLIO {{ routing.folio }}</span>
                                </h6>
                                <table class="table-bordered w-100 small text-small">
                                    <tr v-if="routing.docto && !isRoleOutput">
                                        <td><strong>Importe Fact.: </strong> {{ formatCurrency(routing.importe_Neto) }}</td>
                                        <td><strong>Saldo Fact.: </strong> {{ formatCurrency(routing.docto.doctosPVSaldo[0].saldo_Cargo) }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><small><feather-icon icon="MapPinIcon" /> {{ routing.calle }}, {{ routing.num_Exterior }} {{ routing.colonia }}</small></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><strong>Vendedor: </strong> {{ routing.nombre_Vendedor }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Contacto: </strong> {{ routing.contacto }}</td>
                                        <td><strong>Teléfono: </strong> {{ routing.telefono1 }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><strong>Referencia: </strong> {{ routing.direccion_Referencia }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"><strong>Comentarios de Ruta: </strong> 
                                            <small>{{ routing.observaciones }}</small>
                                        </td>
                                    </tr>
                                </table>
                            </b-col>

                            <b-col cols="6" class="mt-1">
                                <b-form-group label="Almacen Logistica" label-for="warehouse_logistic_id" label-class="font-weight-bold">
                                    <v-select
                                        :disabled="routing.camion_Id != null 
                                        && routing.chofer_Id != null 
                                        && routing.fecha_Asignacion_Chofer != null"                                     
                                        id="warehouse_logistic_id"
                                        v-model="routing.almacen_Logistica_Id"
                                        :options="wareHouses.filter(a => a.almacen_Id != 0)"
                                        label="nombre"
                                        :reduce="option => option.almacen_Id"
                                        :clearable="false"
                                        :searchable="false"
                                        class="select-size-sm"
                                        placeholder="Selecciona una opción">
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6"></b-col>

                            <b-col cols="6">
                                <b-form-group label="Chofer" label-for="chofer" label-class="font-weight-bold">
                                    <v-select
                                        :disabled="routing.camion_Id != null 
                                        && routing.chofer_Id != null 
                                        && routing.fecha_Asignacion_Chofer != null"
                                        v-model="routing.chofer_Id"
                                        id="chofer"
                                        :options="operators" 
                                        :reduce="option => option.id"
                                        label="label"
                                        :clearable="true"
                                        :searchable="true"
                                        class="select-size-sm"
                                        placeholder="Selecciona una opción"
                                        @input="selectOperator">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label="Camion" label-for="truck" label-class="font-weight-bold">
                                    <!-- 
                                        && routing.chofer_Id != null 
                                        && routing.fecha_Asignacion_Chofer != null
                                     -->
                                    <v-select
                                        :disabled="(routing.camion_Id != null && routing.chofer_Id == 1) || routing.fecha_Asignacion_Chofer != null"
                                        v-model="routing.camion_Id"
                                        id="truck"
                                        :options="trucks"
                                        :reduce="option => option.id"
                                        label="label"
                                        :clearable="true"
                                        :searchable="true"
                                        class="select-size-sm"
                                        placeholder="Selecciona una opción">
                                    </v-select>
                                </b-form-group>
                            </b-col>                            
                        </b-row>
                    </div>

                    <!-- Tabs -->
                    <b-tabs fill v-model="activeTabArticles">
                        <b-tab>
                            <template #title>
                                <feather-icon icon="BoxIcon" />
                                <span>Articulos</span>
                            </template>
                            <articles-invoice :isEdit="false" :articles="articles" :loading="loading" :isSticky="true" :showClienteRecibe="false"/>
                        </b-tab>

                        <b-tab @click="getReturns">
                            <template #title>
                                <feather-icon icon="RefreshCwIcon" />
                                <span>Vueltas</span>
                            </template>

                            <table-returns v-if="returns" :returns="returns" />
                        </b-tab>

                        <!-- Historial de Facturas -->
                        <b-tab @click="getHistorial" title="Historial de Facturas">
                            <template #title>
                                <feather-icon icon="ClockIcon" />
                                <span>Historial de Facturas</span>
                            </template>

                            <table-history :transfers="historials" />
                        </b-tab>

                        <!-- Comentarios -->
                        <b-tab @click="getComments" title="Historial de Facturas">
                            <template #title>
                                <feather-icon icon="MessageSquareIcon" />
                                <span>Comentarios</span>
                            </template>
                            <table-comments v-if="routing && routing.docto_Pv_Id" :doctoPvId="routing.docto_Pv_Id" />
                        </b-tab>

                    </b-tabs>

                    <!-- Actions -->
                    <div v-if="activeTabArticles === 0 && !isAuditor && !isAdministrative" class="m-1">
                        <b-row>
                            <b-col cols="12">
                                <div v-if="routing && routing.docto && routing.docto.estatus.trim() !== 'C'" class="demo-inline-spacing float-right">
                                    
                                    <b-button v-if="routing.estatus == 'CERRADA'" @click="openInvoice" :disabled="loading" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-warning">
                                        <feather-icon icon="CornerUpLeftIcon" />
                                        Re Abrir Factura
                                    </b-button>

                                    <!-- Este boton solo debe ser si  -->
                                    <b-button v-if="!routing.fecha_Asignacion_Chofer" :disabled="loading" @click="assignTruck" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                                        <feather-icon icon="TruckIcon" />
                                        Asignar Chofer
                                    </b-button>

                                    <!-- El cambio de chofer solo debe aparecer si ya se enruto la factura y tenga asignacion -->
                                    <b-button v-if="routing.fecha_Asignacion_Chofer && routing.estatus === 'EN RUTA'" :disabled="loading" 
                                        @click="isActiveModalChofer = true" size="sm" variant="warning">
                                            <feather-icon icon="TruckIcon" />
                                            Cambiar Chofer
                                    </b-button>
                                                                        
                                    <b-button v-if="canClosed" @click="closingInvoice" :disabled="!canClosed" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
                                        <feather-icon icon="XIcon" /> Cerrar Factura
                                    </b-button>

                                    <!--  -->
                                    <!-- <close-invoice ref="closeInvoiceComponent" :routing="routing" v-if="routing.fecha_Asignacion_Chofer && routing.estatus === 'EN RUTA'" /> -->
                                    <b-button disabled v-if="!routing.fecha_Asignacion_Chofer" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary">
                                        <feather-icon icon="PrinterIcon" /> Imprimir Hoja de Control
                                    </b-button>

                                    

                                    <delivery-document ref="printDocumentComponent" v-else-if="hasRouting && routing.fecha_Asignacion_Chofer" 
                                    :doctoPvId="routing.docto_Pv_Fte_Id" :folio="routing.folio" :routing="routing" @print-simplified="clickPrint"/>
                                </div>
                                <div v-if="routing && routing.docto && routing.docto.estatus.trim() === 'C'">
                                    <b-alert variant="danger" show>
                                        <div class="alert-body">
                                            <feather-icon
                                                class="mr-25"
                                                icon="AlertTriangleIcon"
                                            />
                                            <span><strong>NOTA:</strong> La factura esta cancelada</span>
                                        </div>
                                    </b-alert>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-col>         
        </b-row>

        <!-- Sidebar order colums  -->
        <b-sidebar id="sidebar-right" ref="sidebarOrder" bg-variant="white" right backdrop shadow>
            <div class="text-center">
                <b-card-text class="mt-2 h5 color-inherit text-reset">
                    Ordenar Columnas
                </b-card-text>
                <b-form-checkbox v-if="activeTab === 0" v-model="orderColumnDesc" inline>
                    <span class="h6">Descendente</span>
                </b-form-checkbox>

                <b-form-checkbox v-if="activeTab === 1" v-model="descendentAssigned" inline>
                    <span class="h6">Descendente</span>
                </b-form-checkbox>

                <b-form-checkbox v-if="activeTab === 2" v-model="descendentClosed" inline>
                    <span class="h6">Descendente</span>
                </b-form-checkbox>
            </div>
            
            

            <b-list-group class="mt-3_ _rounded-0">
                <b-list-group-item v-for="item in columnsToDisplay" :key="item.key" class="bg-transparent">                                                        
                    
                    <b-form-checkbox v-if="activeTab === 0" v-model="orderColumns" :value="item.key" inline>
                        <span v-text="item.label"/>
                    </b-form-checkbox>

                    <b-form-checkbox v-if="activeTab === 1" v-model="orderColumnsAssigneds" :value="item.key" inline>
                        <span v-text="item.label"/>
                    </b-form-checkbox>

                    <b-form-checkbox v-if="activeTab === 2" v-model="orderColumnsClosed" :value="item.key" inline>
                        <span v-text="item.label"/>
                    </b-form-checkbox>

                </b-list-group-item>
            </b-list-group>

            <template #footer>
                <div class="p-2 border-top text-danger cursor-pointer">
                    <b-button @click="applyOrderColumns" variant="primary">
                        <feather-icon icon="CheckIcon" />
                        <span class="ml-1">Aplicar</span>
                    </b-button>
                </div>
            </template>
        </b-sidebar>

        <!-- Modal Chofer -->
        <b-modal ref="infoModalRef" id="modalUpdateChofer" title="Cambiar Chofer/Unidad" no-close-on-backdrop hide-footer
            @cancel="isActiveModalChofer = false" @hidden="closeModalChofer" :visible="isActiveModalChofer" size="md">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="Chofer" label-for="chofer" label-class="font-weight-bold">
                        <v-select
                            id="chofer"
                            v-model="newChofer"
                            :options="operators" 
                            :reduce="option => option.id"
                            label="label"
                            :clearable="false"
                            :searchable="false"
                            class="select-size-sm"
                            placeholder="Selecciona una opción"
                            @input="selectOperator">
                        </v-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group label="Camion" label-for="truck" label-class="font-weight-bold">
                        <v-select     
                            :disabled="newChofer == 1"                       
                            id="truck"
                            v-model="newCamion"
                            :options="trucks"
                            :reduce="option => option.id"
                            label="label"
                            :clearable="false"
                            :searchable="false"
                            class="select-size-sm"
                            placeholder="Selecciona una opción">
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <div class="text-right mt-2">                
                <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                    class="mr-2" variant="outline-secondary" @click="closeModalChofer">
                    Cancelar
                </b-button>

                <b-button size="sm" :disabled="loading" @click="changeOperator" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    type="button">
                    Guardar
                </b-button>
            </div>
        </b-modal>
    </b-overlay>
</template>

<script>
import { HubConnectionBuilder } from "@microsoft/signalr";
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import store from "@/store";
import router from "@/router";
import { mapGetters } from 'vuex';
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import vSelect from "vue-select";
import { heightTransition } from '@core/mixins/ui/transition'
import EventBus from "@/event-bus"

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

import DoctosPVProvider from "@/providers/DoctosPV"
import FeatherIcon from '../feather-icon/FeatherIcon.vue';
const DoctosPVResource = new DoctosPVProvider()

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
import moment from 'moment';
const DeliveryRoutesResource = new DeliveryRoutesProvider();

import CollectorsProvider from '@/providers/Collectors'
const CollectosResource = new CollectorsProvider()

import ArticlesInvoice from '@/@core/components/invoices/ArticlesInvoice.vue';
import TableReturns from '@/@core/components/invoices/TableReturns.vue';
import TableHistory from '@/@core/components/invoices/TableHistory.vue';
import DeliveryDocument from '@/@core/components/app-rountings/DeliveryDocument.vue'
import CloseInvoice from '@/@core/components/invoices/CloseInvoice.vue';
import TableComments from '@/@core/components/invoices/TableComments.vue'

import WebOperadoresProvider from '@/providers/WebOperadores';
const WebOperadoresResource = new WebOperadoresProvider();

import WebCamionesProvider from '@/providers/WebCamiones';
const WebCamionesResource = new WebCamionesProvider();

export default {
    name: 'LogisticPanel',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        FeatherIcon,
        BTabs,
        BTab,
        BListGroup, 
        BListGroupItem,
        flatPickr,
        vSelect,

        //
        ArticlesInvoice,
        DeliveryDocument,
        TableReturns,
        TableHistory,
        CloseInvoice,
        TableComments,
    },
    mixins: [heightTransition],
    props: {
        isRefresh: {
            type: Boolean,
            default: () => false,
        },        
    },
    data() {
        return {
            loading: false,
            routings: [],
            assigneds: [],
            closeds: [],
            routing: {
                estatus: ''
            },
            historials: [],
            collectors: [],
            operators: [],
            trucks: [],
            filter: {
                date_start: moment().add(-3, 'month').startOf('month').format('YYYY-MM-DD'),
                date_end: moment().format('YYYY-MM-DD'),
                status: 'ABIERTA', //'ABIERTA,EN RUTA,CERRADA',
                chofer_Id: null,
                camion_Id: null,

                date_start_closed: moment().add(-3, 'month').startOf('month').format('YYYY-MM-DD'),
                date_end_closed: moment().format('YYYY-MM-DD'),
                status_closed: 'CERRADA', //'ABIERTA,EN RUTA,CERRADA',
                warehouse_closed_Id: 0,
                searchTextClosed: '',
            },
            columns: [
                {
                    key: 'folio',
                    label: 'Folio',
                    // sortable: true
                },
                {
                    key: 'fecha_Factura',
                    label: 'Fecha Factura',
                    // sortable: true
                },
                {
                    key: 'nombre_Sucursal',
                    label: 'Origen',
                    // sortable: true
                },
                {
                    key: 'nombre_Cliente',
                    label: 'Cliente',
                    // sortable: true
                },
                {
                    key: 'nombre_Almacen',
                    label: 'Almacen',
                    // sortable: true
                },
                {
                    key: 'portador',
                    label: 'Portador',
                    // sortable: true
                },
                {
                    key: 'asignado',
                    label: 'Asignado',
                    // sortable: true
                },
                {
                    key: 'saldo',
                    label: 'Saldo',
                },
                {
                    key: 'estatus',
                    label: 'Estatus',
                },
                {
                    key: 'instructions',
                    label: 'Instrucciones',
                    tdClass: 'text-center'
                }

            ],
            columnAssigned: [
                {
                    key: 'pending',
                    label: 'Por Entregar',
                },
                {
                    key: 'instructions',
                    label: 'Instrucciones',
                    tdClass: 'text-center'
                },
                {
                    key: 'unidad',
                    label: 'Unidad',
                    thClass: 'text-center',
                },
                {
                    key: 'chofer',
                    label: 'Chofer',
                    thClass: 'text-center',
                    tdClass: 'text-truncate',
                },
                {
                    key: 'fecha_Factura',
                    label: 'Fecha Factura',
                },
                {
                    key: 'fecha_Asignacion_Chofer',
                    label: 'Fecha Asignación',
                    thClass: 'text-center',
                },
                {
                    key: 'folio',
                    label: 'Factura',
                    thClass: 'text-center',
                },
                {
                    key: 'cliente',
                    label: 'Cliente',
                    thClass: 'text-center',
                },
                {
                    key: 'direccion',
                    label: 'Dirección',
                    thClass: 'text-center',
                }
            ],
            columnsClosed: [
                {
                    key: 'folio',
                    label: 'Folio',
                    // sortable: true
                },
                {
                    key: 'fecha_Factura',
                    label: 'Fecha Factura',
                    // sortable: true
                },
                {
                    key: 'nombre_Sucursal',
                    label: 'Origen',
                    // sortable: true
                },
                {
                    key: 'nombre_Cliente',
                    label: 'Cliente',
                    // sortable: true
                },
                {
                    key: 'nombre_Almacen',
                    label: 'Almacen',
                    // sortable: true
                },
                {
                    key: 'portador',
                    label: 'Portador',
                    // sortable: true
                },
                {
                    key: 'asignado',
                    label: 'Asignado',
                    // sortable: true
                },
                {
                    key: 'saldo',
                    label: 'Saldo',
                },
                {
                    key: 'estatus',
                    label: 'Estatus',
                },
                {
                    key: 'instructions',
                    label: 'Instrucciones',
                    tdClass: 'text-center'
                }

            ],
            articles: [],
            searchText: '',
            searchTextAssigned: '',
            wareHouses: [],
            wareHouse_Id: 0,
            isActiveModalChofer: false,
            newChofer: '',
            newCamion: '',
            selectedRow: null,
            columnsToDisplay: [],
            orderColumns: [],
            orderColumnsAssigneds: [],
            orderColumnsClosed: [],
            orderColumnDesc: false,
            descendentAssigned: false,
            descendentClosed: false,
            activeTab: 0,

            //Section assigned
            currentPage: 1,
            pageSize: 10,
            totalPages: 0,
            totalRecords: 0,
            returns: [],
            activeTabArticles: 1,
            selectedRowIndex: -1,

            //Setcion routings
            currentPageRouting: 1,
            pageSizeRouting: 10,
            totalPagesRouting: 0,
            totalRecordsRouting: 0,
            selectRowIndexRouting: -1,

            //Section routings closed
            closeCurrentPageRouting: 1,
            closePageSizeRouting: 15,
            closeTotalPagesRouting: 0,
            closeTotalRecordsRouting: 0,
            selectRowIndexRoutingClosed: -1,

            //
            hubConnection: null,
        }
    },
    computed: {
        isAdministrative () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleAdministrative'] : false
        },
        showColumnOrigin() {
            return this.findSetting('ACTIVAR_COLUMNA_ORIGEN_LOGISTICA') || false
        },
        isRealodByNotification() {
            return store.getters['routing/getIsRealodAbierta']
        },
        isRoleOutput() {
            return store.getters['auth/getUser'].customRol.id === '5c1e9a77-e7a1-40fe-ba71-6d5c65cea4e0'
        },
        dataMeta() {
            const localItemsCount = _.size(this.filterAssigneds);
            return {
                from:
                    this.pageSize * (this.currentPage - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.pageSize * (this.currentPage - 1) + localItemsCount,
                of: this.totalRecords,
            };
        },
        dataMetaRouting() {
            const localItemsCount = _.size(this.filterRoutings);
            return {
                from:
                    this.pageSizeRouting * (this.currentPageRouting - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.pageSizeRouting * (this.currentPageRouting - 1) + localItemsCount,
                of: this.totalRecordsRouting,
            };
        },
        dataMetaClosedRouting() {
            const localItemsCount = _.size(this.filterClosed);
            return {
                from:
                    this.closePageSizeRouting * (this.closeCurrentPageRouting - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.closePageSizeRouting * (this.closeCurrentPageRouting - 1) + localItemsCount,
                of: this.closeTotalRecordsRouting,
            };
        },
        isSelectDisabledChofer() {
            return (
                (this.routing.camion_Id != null && 
                this.routing.chofer_Id != null && 
                this.routing.fecha_Asignacion_Chofer != null) 
                ||
                this.routing.chofer_Id == 1
            );
        },
        hasRouting() {
            return Object.keys(this.routing).length > 0;
        },
        canClosed() { //40 => Permiso Para Cerrar Factura
            return !this.loading && this.routing.estatus !== 'CERRADA' && this.routing.estatus == 'EN RUTA' && this.canAccess(40);
        },
        filterAssigneds() {

            //Primero aplicamos el filtro de chofer_Id y camion_Id
            // let results = this.assigneds.filter(item => {
            //     return (this.filter.chofer_Id ? item.chofer_Id == this.filter.chofer_Id : true) &&
            //         (this.filter.truck_Id ? item.camion_Id == this.filter.truck_Id : true)
            // })

            // //Ahora una vez aplicado el filtro igual que se aplique el filtro por folio, origen, cliente, portador, asignado, nombre_sucursal
            // return this.searchTextAssigned ? results.filter(item => {
            //     return item.folio?.toLowerCase().includes(this.searchTextAssigned.toLowerCase()) ||
            //         item.nombre_Cliente?.toLowerCase().includes(this.searchTextAssigned.toLowerCase()) ||
            //         item.nombre_Sucursal?.toLowerCase().includes(this.searchTextAssigned.toLowerCase()) ||
            //         item.portador?.toLowerCase().includes(this.searchTextAssigned.toLowerCase()) ||
            //         item.almacen?.toLowerCase().includes(this.searchTextAssigned.toLowerCase()) ||
            //         item.asignado?.toLowerCase().includes(this.searchTextAssigned.toLowerCase())
            // }) : results            
            return this.assigneds
        },
        filterRoutings() {
            // let results = this.wareHouse_Id && this.wareHouse_Id != 0 ? this.routings.filter(item => item.almacen_Id == this.wareHouse_Id) : this.routings
            // return this.searchText ? results.filter(item => {
            //     return item.folio?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            //         item.origen?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            //         item.nombre_Cliente?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            //         item.nombre_Sucursal?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            //         item.portador?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            //         item.nombre_Almacen?.toLowerCase().includes(this.searchText.toLowerCase()) ||
            //         item.asignado?.toLowerCase().includes(this.searchText.toLowerCase())
            // }) : results
            return this.routings
        },
        filterClosed() {
            return this.closeds
        },
        ...mapGetters({
          getWareHouses: 'auth/getWareHouses',
          logisticSetting: 'auth/getLogisticSetting',
        }),
        isAuditor () {
            return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == "d5b8906f-b8a3-4072-b18e-5a96757640a0" : false
        },
    },
    created() {
        this.loadWareHouses();
    },
    async mounted() {
        await this.getOperators()
        await this.getTrucks();
        await this.getDeliveryRoutings(false, this.currentPageRouting)
    },
    methods: {
        getComments() {
            console.log(this.routing)
        },
        selectTruck(evgt) {
            this.currentPage = 1
        },
        selectChofer(evt) {
            this.currentPage = 1    
        },
        async getReturns() {
            //this.activeTabArticles = 0
            if (this.returns.length > 0) {
                return
            }

            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.getReturns(this.routing.docto_Pv_Id)
                this.loading = false
                if (data.isSuccesful) {
                    this.returns = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },      
        async getHistorial() {
            // if (this.historials.length > 0) {
            //     return
            // }

            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.getHistorial(this.routing.docto_Pv_Id)
                this.loading = false
                if (data.isSuccesful) {
                    this.historials = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },        
        clickPrint() {
            //invoiceActions
            
        },
        applyOrderColumns() {
            store.dispatch('auth/UPDATE_LOGISTIC_SETTING',  {
                    descendent: this.orderColumnDesc,
                    descendentAssigned: this.descendentAssigned,
                    descendentClosed: this.descendentClosed,
                    orderColumns: this.orderColumns.length > 0 ? this.orderColumns.map(item => ({key: item, order: this.orderColumnDesc ? 'desc' : 'asc'})) : [],
                    orderColumnsAssigneds: this.orderColumnsAssigneds.length > 0 ? this.orderColumnsAssigneds.map(item => ({key: item, order: this.descendentAssigned ? 'desc' : 'asc'})) : [],
                    orderColumnsClosed: this.orderColumnsClosed.length > 0 ? this.orderColumnsClosed.map(item => ({key: item, order: this.descendentClosed ? 'desc' : 'asc'})) : []
                }
            )

            if(this.activeTab === 0) {
                this.routings = _.orderBy(
                    this.routings,
                    this.orderColumns,
                    this.orderColumns.map(() => (this.orderColumnDesc ? 'desc' : 'asc'))
                );
            } else if(this.activeTab === 1) {

                this.assigneds = _.orderBy(
                    this.assigneds,
                    this.orderColumnsAssigneds,
                    this.orderColumnsAssigneds.map(() => (this.descendentAssigned ? 'desc' : 'asc'))
                );
            } else if(this.activeTab === 2) {
                this.closeds = _.orderBy(
                    this.closeds,
                    this.orderColumnsClosed,
                    this.orderColumnsClosed.map(() => (this.descendentClosed ? 'desc' : 'asc'))
                );
            }

            this.$refs.sidebarOrder.hide();
        },
        async changeOperator() {

            if (!this.newChofer || !this.newCamion) {
                this.danger('Debes seleccionar un chofer y/o una unidad para continuar')
                return false
            }

            //Buscamos los datos del chofer por el nuevo seleccionado
            const operator = this.operators.find(item => item.id == this.newChofer);

            //Buscamos los datos del camion por el nuevo seleccionado
            const truck = this.trucks.find(item => item.id == this.newCamion);

            this.routing.rutasEntregasDetalles.forEach(element => {
                //element.id = 0
                element.chofer_Id = this.newChofer
                element.nombre_Chofer = operator ? `${operator.nombre} ${operator.apellidos}` : null
                element.camion_Id = this.newCamion
                element.nombre_Camion = truck ? `${truck.identificador} - ${truck.placas}` : null
                //element.unidades = 0
            });

            const payload = {...this.routing}

            //Reasignamos el chofer y camion
            payload.chofer_Id = this.newChofer
            payload.camion_Id = this.newCamion
            payload.isPending = false

            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.update(payload)
                this.loading = false
                if(data.isSuccesful) {
                    this.isActiveModalChofer = false
                    this.success(`Chofer unidad actualizada con éxito`)
                    
                    this.$nextTick(async() => {
                        this.routing = {...data.data,  portador: data.data.webOperadores ? data.data.webOperadores.nombre + ' ' + data.data.webOperadores.apellidos : null,
                        asignado: data.data.webOperadores ? data.data.webOperadores.nombre + ' ' + data.data.webOperadores.apellidos : null}
                        
                        //Ahora encontrar el routing en la lista de routings y actualizarlo
                        const index = this.routings.findIndex(item => item.id == data.data.id)
                        this.routings[index].asignado = data.data.webOperadores ? data.data.webOperadores.nombre + ' ' + data.data.webOperadores.apellidos : null
                        this.routings[index].portador = data.data.webOperadores ? data.data.webOperadores.nombre + ' ' + data.data.webOperadores.apellidos : null
                    })                    
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        closeModalChofer() {
            this.isActiveModalChofer = false
        },
        loadWareHouses() {
            // Obtener la lista de almacenes del store
            const wareHousesFromStore = this.getWareHouses;

            // Crear el nuevo objeto de almacén
            const allWareHousesOption = {
                almacen_Id: 0,
                nombre: 'TODAS'
            };

            // Agregar el nuevo objeto al inicio de la lista
            this.wareHouses = [allWareHousesOption, ...wareHousesFromStore];
        },
        diasPlazoLabel(item) {
            if (item.docto && item.docto.doctosPVSaldo && item.docto.doctosPVSaldo.length > 0 && item.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'PAGADA'
            }

            if (item.cliente.dias_Plazo == 0) {
                return 'P.A.R'
            }
            if (item.cliente.dias_Plazo > 0) {
                return 'CLIENTE FIRMA'
            }
        },
        diasPlazoVariant(item) {
            if (item.docto && item.docto.doctosPVSaldo && item.docto.doctosPVSaldo.length > 0 && item.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'success'
            }

            if (item.cliente.dias_Plazo == 0) {
                return 'danger'
            }

            if (item.cliente.dias_Plazo > 0) {
                return 'warning'
            }
        },
        selectOperator(evt) {
            if (evt === 1) {
                const truck = this.trucks.find(item => item.id == 1);
                this.routing.camion_Id = truck ? truck.id : null    
            } else {
                this.routing.camion_Id = null
            }
        },
        changeTab(index = 0) {
            this.routing = {}
            this.activeTab = index

            this.selectRowIndexRouting = -1
            this.selectedRowIndex = -1
            this.selectRowIndexRoutingClosed = -1

            if (index === 0) {
                this.selectedRowIndex = -1
            } if (index === 1) {
                this.selectRowIndexRouting = -1
                this.$nextTick(async () => {
                    await this.getAssignedRoutings(1)
                })
            } else if (index === 2) {
                this.selectRowIndexRoutingClosed = -1
                this.$nextTick(async () => {
                    await this.getClosedRoutings(1)
                })
            }
        },
        async getAssignedRoutings(pageNumber = 1) {
            this.assigneds = []
            this.selectedRowIndex = -1
            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.getAssignedRoutes({
                    numeroPagina: pageNumber,
                    tamanoPagina: this.pageSize,
                    search: this.searchTextAssigned || '',
                    choferId: this.filter.chofer_Id,
                    camionId: this.filter.truck_Id,
                    status: 'EN RUTA',
                })
                this.loading = false
                if(data.isSuccesful) {
                    this.assigneds = _.orderBy(
                        data.data.map(item => ({
                            ...item,
                            chofer: item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : '',
                            unidad: item.webCamiones ? item.webCamiones.identificador + ' - ' + item.webCamiones.placas : '',
                            pending: item.articulos.reduce((carry, item) => {
                                return carry + item.pendiente;                 
                            }, 0),
                            direccion: `${item.calle} ${item.num_Exterior} ${item.colonia}`
                        })),
                        this.orderColumnsAssigneds,
                        this.orderColumnsAssigneds.map(() => (this.descendentAssigned ? 'desc' : 'asc'))
                    );

                    this.totalPages = data.totalPages;
                    this.totalRecords = data.totalRecords;
                    this.currentPage = data.currentPage;

                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getClosedRoutings(pageNumber = 1) {
            this.closeds = []
            this.routing = {}
            this.selectRowIndexRoutingClosed = -1

            const query = {
                fechaInicio: this.filter.date_start_closed,
                fechaFin: this.filter.date_end_closed,
                numeroPagina: pageNumber,
                tamanoPagina: this.closePageSizeRouting,
                search: this.filter.searchTextClosed || '',  
                almacenId: this.filter.warehouse_closed_Id,
                status: 'CERRADA'
            }

            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.getAllByFilters(query)
                this.loading = false
                if(data.isSuccesful) {
                    this.closeds = _.orderBy(
                        data.data.map(
                            item => ({...item, 
                            saldo: item.docto.doctosPVSaldo[0].saldo_Cargo, 
                            nombre_Sucursal: item.docto.nombre_Sucursal,
                            portador: item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : null,
                            asignado: item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : null,
                        })),
                        this.orderColumnsClosed,
                        this.orderColumnsClosed.map(() => (this.descendentClosed ? 'desc' : 'asc'))
                    )

                    this.closeTotalPagesRouting = data.totalPages;
                    this.closeTotalRecordsRouting = data.totalRecords;
                    this.closeCurrentPageRouting = data.currentPage;

                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
           
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage += 1;
                this.getAssignedRoutings(this.currentPage);
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage -= 1;
                this.getAssignedRoutings(this.currentPage);
            }
        },
        openInvoice() {
            this.$swal.fire({
                title: `Re Abrir Factura`,
                html: `¿Está seguro de que desea re abrir la factura <b>${this.routing.folio}</b>?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await DeliveryRoutesResource.reOpenInvoice(this.routing.id)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.routing = {}
                            this.success(`La factura ha sido re abierta con éxito`)
                            await this.getDeliveryRoutings()
                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        },
        handlePdfProgress(evt) {            
            this.loading = true
            if (evt >= 100) {
                this.loading = false
                this.routing = {
                    estatus: ''
                }
            }
        },
        async getInvoiceArticles() {
            console.log('this.routing in logistic', this.routing)   
            try {
                this.loading = true
                const { data } = await DoctosPVResource.getArticles(this.routing.docto_Pv_Fte_Id, "V")
                this.loading = false
                if (data.isSuccesful) {
                    this.articles = data.data.map(item => ({...item, cargado: 0, oldPending: parseFloat(item.pendiente)}))                    
                    this.routing.articles = this.articles
                } else {
                    this.danger(data.message)
                }
            } catch (e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async assignTruck() {

            const saldo = this.routing.saldo
            if (!this.routing.chofer_Id || !this.routing.camion_Id) {
                this.danger(`Debe seleccionar el chofer y la unidad correspondiente`)
                return false
            }

            const operator = this.operators.find(item => item.id == this.routing.chofer_Id);
            const truck = this.trucks.find(item => item.id == this.routing.camion_Id);

            this.routing.rutasEntregasDetalles.forEach(element => {
                element.chofer_Id = this.routing.chofer_Id
                element.nombre_Chofer = operator ? operator.nombre : null
                element.camion_Id = this.routing.camion_Id
                element.nombre_Camion = truck ? `${truck.identificador} - ${truck.placas}` : null
            });

            this.routing.isPending = false
            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.update(this.routing)
                this.loading = false
                if(data.isSuccesful) {
                    this.success(`Chofer unidad asignada con éxito`)
                    this.routing.estatus = data.data.estatus
                    this.routing.fecha_Asignacion_Chofer = data.data.fecha_Asignacion_Chofer
                    this.routing.webCamiones = data.data.webCamiones
                    this.routing.webOperadores = data.data.webOperadores
                    this.routing.saldo = data.data.docto.doctosPVSaldo[0].saldo_Cargo
                    this.routing.nombre_Sucursal = data.data.docto.nombre_Sucursal
                    this.routing.portador = data.data.webOperadores ? data.data.webOperadores.nombre + ' ' + data.data.webOperadores.apellidos : null,
                    this.routing.asignado = data.data.webOperadores ? data.data.webOperadores.nombre + ' ' + data.data.webOperadores.apellidos : null,

                    // Hacer clic en el botón del componente hijo
                    // Asegúrate de que el componente hijo esté montado
                    this.$nextTick(() => {
                        // Emitir el evento personalizado
                        this.$refs.printDocumentComponent.$emit('trigger-print', this.routing.docto_Pv_Id);
                        
                        //Actualizar los routings mapeando el elemento actual
                        this.routings = this.routings.map(r => {
                            if (r.id == this.routing.id) {
                                return this.routing
                            }
                            return r
                        })                     
                    });
                        
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        async getTrucks() {
            if (this.trucks.length <= 0) {
                this.loading = true
                const { data } = await WebCamionesResource.index({})
                this.loading = false
                if (data.isSuccesful) {
                    this.trucks = data.data.map(item => ({...item, label: `${item.identificador} - ${item.placas}`})).filter(item => item.activo)
                }   
            }
        },
        async closingInvoice() {
            const payload = {...this.routing, estatus: 'CERRADA'}
            const isPending = this.routing.articles.reduce((carry, item) => {
                return carry + item.pendiente;                 
            }, 0) > 0

            let message = `¿Está seguro de que desea cerrar la factura <b>${this.routing.folio}</b>? <br><br><p>Esta acción no se puede revertir.</p>`
            if (isPending) {
                // message = `La factura <b>${this.routing.folio}</b> tiene artículos pendientes por entregar. <br> ¿Está seguro de que desea cerrarla y darle salida a los pendientes? <br><br><p>Esta acción no se puede revertir.</p>`
                message = `La factura <b>${this.routing.folio}</b> tiene artículos pendientes por entregar. <br> Elige una opción para continuar <br><br><p>Esta acción no se puede revertir.</p>`
            }

            this.$swal.fire({
                title: `Cerrar Factura`,
                html: message,
                icon: 'question',
                showCancelButton: true,
                showDenyButton: isPending,
                confirmButtonText: isPending ? 'Cerrar Con Pendientes' : 'Continuar',
                denyButtonText: 'Cerrar y Generar Salidas',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: isPending ? "btn btn-block btn-primary" : "btn btn-primary",
                    denyButton: "btn btn-block btn-warning mt-1",
                    cancelButton: isPending ? "btn btn-block btn-outline-danger mt-1" : "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    console.log('is confirm')
                    payload.isPending = false                    
                    try {
                        this.loading = true
                        const { data } = await DeliveryRoutesResource.update(payload)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.routing = {}
                            this.success(`La factura ha sido cerrada con éxito`)
                            if (this.activeTab == 0) {
                                await this.getDeliveryRoutings()
                            } else {
                                this.selectedRowIndex = -1
                                this.assigneds = this.assigneds.filter(item => item.docto_Pv_Id !== payload.docto_Pv_Id)
                            }
                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                },
                preDeny: async () => {
                    console.log('is deny')
                    payload.isPending = true                    
                    try {
                        this.loading = true
                        const { data } = await DeliveryRoutesResource.update(payload)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.routing = {}
                            this.success(`La factura ha sido cerrada con éxito`)
                            if (this.activeTab == 0) {
                                await this.getDeliveryRoutings()
                            } else {
                                //alert(this.rountig.id + ' ' + this.routing.folio)
                                this.selectedRowIndex = -1
                                this.assigneds = this.assigneds.filter(item => item.docto_Pv_Id !== payload.docto_Pv_Id)
                            }                            
                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        },
        async getOperators () {
            if (this.operators.length <= 0) {
                this.loading = true
                const { data } = await WebOperadoresResource.index({})
                this.loading = false
                if (data.isSuccesful) {
                    this.operators = data.data.map(item => ({...item, label: `${item.nombre} ${item.apellidos}`})).filter(item => item.activo && item.tipo == 'Chofer')
                }
            }
        },
        async printDocument() {            


            try {
                // this.loading = true
                // const { data } = await DeliveryRoutesResource.deliveryDocument(this.routing.docto_Pv_Id)
                // this.loading = false

                // const fileURL = URL.createObjectURL(data.blob);
                // window.open(fileURL, '_blank');
                
                // // Crear un objeto Blob con los datos del PDF
                // const blob = new Blob([data], { type: 'application/pdf' });
                
                // // Crear una URL para el Blob
                // const url = window.URL.createObjectURL(blob);
                
                // // Especificar el nombre del archivo
                // const filename = `${this.routing.folio}.pdf`;

                // // Abrir el PDF en una nueva ventana del navegador con el nombre especificado
                // window.open(url, '_blank', `toolbar=no,scrollbars=yes,resizable=yes,top=100,left=100,width=800,height=600,filename=${filename}`);

            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        selectRow(item, index) {
            this.routing = {
                estatus: ''
            }
            this.returns = []  
            this.activeTabArticles = 0          
            if (item) {
                if (this.activeTab == 0) {
                    this.selectRowIndexRouting = index;
                } else if (this.activeTab == 1) {
                    this.selectedRowIndex = index;
                } else if(this.activeTab == 2) {
                    this.selectRowIndexRoutingClosed = index;
                }
                
                this.$nextTick( async () => {
                    this.routing = {...item, articles: []}
                    await this.getInvoiceArticles()
                })
            }
        },
        isSelected(index) {
            // Determina si la fila en el índice dado está seleccionada
            if (this.activeTab == 0) {
                return index === this.selectRowIndexRouting;
            } else if (this.activeTab == 1) {
                return index === this.selectedRowIndex;
            } else if (this.activeTab == 2) {
                return index === this.selectRowIndexRoutingClosed;
            }
        },
        async getCollectors () {
            
            try {
                this.loading = true
                const { data } = await CollectosResource.index({ chofer: 'S'})            
                this.loading = false
                if (data.isSuccesful) {
                    this.collectors = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false                
                this.handleResponseErrors(e)
            }
        },
        async getDeliveryRoutings (isClearSelected = true, pageNumber = 1) {

            this.routings = []
            // if (isClearSelected) {
                // this.$refs.refArticleListTable.clearSelected();
                this.selectRowIndexRouting = -1;
            // }            
            this.routing = {}
            const query = {
                fechaInicio: this.filter.date_start,
                fechaFin: this.filter.date_end,
                numeroPagina: pageNumber,
                tamanoPagina: this.pageSizeRouting,
                search: this.searchText || '',  
                almacenId: this.wareHouse_Id,
                status: this.filter.status === 'TODAS' ? 'ABIERTA, EN RUTA, CLIENTE PASA' : this.filter.status
            }

            store.commit('routing/SET_IS_RELOAD_ABIERTA', false)

            try {
                this.loading = true
                const { data } = await DeliveryRoutesResource.getAllByFilters(query) //getAll(query)
                this.loading = false
                if(data.isSuccesful) {
                    this.routings = _.orderBy(
                        data.data.map(
                            item => ({...item, 
                            saldo: item.docto.doctosPVSaldo[0].saldo_Cargo, 
                            nombre_Sucursal: item.docto.nombre_Sucursal,
                            portador: item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : null,
                            asignado: item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : null,
                        })),
                        this.orderColumns,
                        this.orderColumns.map(() => (this.orderColumnDesc ? 'desc' : 'asc'))
                    )
                    this.totalPagesRouting = data.totalPages;
                    this.totalRecordsRouting = data.totalRecords;
                    this.currentPageRouting = data.currentPage;

                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        }
    },
    watch: {
        closeCurrentPageRouting(value) {
            this.selectRowIndexRouting = -1
            this.getClosedRoutings(value)
        },
        closePageSizeRouting(value) {
            this.selectRowIndexRoutingClosed = -1            
            this.getClosedRoutings(this.closeCurrentPageRouting)
        },
        currentPageRouting(value) {
            // this.getAssignedRoutings(value);
            this.selectRowIndexRouting = -1
            this.getDeliveryRoutings(false, value)
        },
        pageSizeRouting(value) {
            // this.getAssignedRoutings(this.currentPage);
            this.selectRowIndexRouting = -1
            this.getDeliveryRoutings(false, this.currentPageRouting)
        },

        currentPage(value) {
            this.getAssignedRoutings(value);
        },
        pageSize(value) {
            this.getAssignedRoutings(this.currentPage);
        },
        isRefresh(value) {
            console.warn(`refresh is value ${value}`)
        },  
        isActiveModalChofer (value){            
            if (value) {
                this.newChofer = this.routing.chofer_Id
                this.newCamion = this.routing.camion_Id
            }
        },        
        logisticSetting: {
            handler(newVal) {
                if (this.activeTab === 0) {
                    this.orderColumnDesc = newVal.descendent;
                    this.orderColumns = newVal.orderColumns.map(item => item.key);
                } else if (this.activeTab === 1) {
                    this.descendentAssigned = newVal.descendentAssigned;
                    this.orderColumns = newVal.orderColumnsAssigneds.map(item => item.key);
                } else if (this.activeTab === 2) {
                    this.descendentClosed = newVal.descendentClosed;
                    this.orderColumns = newVal.orderColumnsClosed.map(item => item.key);
                }
            },
            immediate: true        
        },
        activeTab(value) {
           //this.columnsToDisplay = value === 0 ? this.columns : this.columnAssigned
            this.columnsToDisplay = value === 0 ? this.columns : value === 1 ? this.columnAssigned : this.columnsClosed
        },
        isRefreshRoutings(value) {
            console.log('isRefreshRoutings', value) 
        },
        loading(value) {
            if (value) {
                store.dispatch('routing/UPDATE_IS_RELOAD_ABIERTA', false)
                store.dispatch('routing/UPDATE_IS_RELOAD_EN_RUTA', false)
                store.dispatch('routing/UPDATE_IS_RELOAD_CERRADA', false)
                store.dispatch('routing/UPDATE_IS_RELOAD_CLIENTE_PASA', false)
            }
        },
    }
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/pages/app-invoice.scss';

</style>
<style>
.b-table th {
    font-size: 9px !important; /* Ajusta el tamaño de fuente según tu necesidad */
}

.b-table td {
    font-size: 10px !important; /* Ajusta el tamaño de fuente según tu necesidad */
}

.b-table-sticky-header {
    overflow-y: auto;
    max-height: 450px;
}

.selected-row {
    background-color: #007bff; /* Color de fondo para la fila seleccionada */
    color: white; /* Color de texto para la fila seleccionada */
}
</style>